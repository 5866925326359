import axios from "axios";

export const getClosures = () => {
  let config: any = {
    method: "get",
    url: process.env.REACT_APP_APIM + "/cierre",
    headers: {
      "API-Key": process.env.REACT_APP_APIM_KEY,
    },
    auth: {
      username: "SAM",
      password: process.env.REACT_APP_APIM_PASS,
    },
  };

  return axios(config);
};

export const getHistory = () => {
  var config: any = {
    method: "get",
    url: process.env.REACT_APP_APIM + "/mesesHistorico",
    headers: {
      "API-Key": process.env.REACT_APP_APIM_KEY,
      Authorization: "Basic " + process.env.REACT_APP_APIM_BEARER,
    },
  };

  return axios(config);
};

export const getMonth = () => {
  var config: any = {
    method: "get",
    url: process.env.REACT_APP_APIM + "/mesesModificar",
    headers: {
      "API-Key": process.env.REACT_APP_APIM_KEY,
    },
    auth: {
      username: "SAM",
      password: process.env.REACT_APP_APIM_PASS,
    },
  };

  return axios(config);
};

export const getTypes = () => {
  var config: any = {
    method: "get",
    url: process.env.REACT_APP_APIM + "/tipos",
    headers: {
      "API-Key": process.env.REACT_APP_APIM_KEY,
    },
    auth: {
      username: "SAM",
      password: process.env.REACT_APP_APIM_PASS,
    },
  };

  return axios(config);
};

export const getPrinexDate = (horas: any) => {
  var config: any = {
    method: "get",
    url: process.env.REACT_APP_APIM + "/horasCortes/" + horas,
    headers: {
      "API-Key": process.env.REACT_APP_APIM_KEY,
      Authorization: "Basic " + process.env.REACT_APP_APIM_BEARER,
    },
  };

  return axios(config);
};
export const addClosure = (data: any) => {
  var config: any = {
    method: "post",
    url: process.env.REACT_APP_APIM + "/ejecucion",
    headers: {
      "API-Key": process.env.REACT_APP_APIM_KEY,
      Authorization: "Basic " + process.env.REACT_APP_APIM_BEARER,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config);
};
