import axios from "axios";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";

export const getAllUsers = () => {
  //   const config = {
  //     headers: { Authorization: `Bearer ${token}` },
  //   };

  return axios.get(process.env.REACT_APP_API_BACKEND + "/user/",getDefaultConfigHeader());
};

export const addUser = (email: any) => {
  return axios.post(
    process.env.REACT_APP_API_BACKEND + "/user/alta?email=" + email,getDefaultConfigHeader()
  );
};
