import { Button, IconButton, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import AedasLoading from "../../Components/AedasLoading/AedasLoading";
import { useFetch, useIsLoading } from "../../Hooks/useFetch";
import { addUser, getAllUsers } from "../../Services/usersService/UserService";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CustomModal from "../../Components/ModalCustom/CustomModal";
import { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("El email debe ser valido")
    .required("El email es requerido"),
});
function Users(props: any) {
  const [users, isFetchingUsers] = useFetch(getAllUsers);
  const [openModal, setOpenModal] = useState(false);
  const isLoading = useIsLoading([isFetchingUsers]);
  const formik = useFormik({
    initialValues: {
      email: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      addUser(values.email)
        .then(() => {
          setOpenModal(false);
          toast.success("Añadido");
        })
        .catch(() => toast.error("Ha habido un problema"));
    },
  });
  const columns = [
    { field: "id", headerName: "ID", width: 90, hide: true },
    {
      field: "display_name",
      headerName: "Nombre",
      sortable: false,

      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      sortable: false,
      flex: 1,
    },
    {
      field: "id_rol",
      headerName: "Rol",
      sortable: false,
      flex: 1,
      valueGetter: (params: any) => {
        return `Admin`;
      },
    },
    {
      field: "active",
      headerName: "Estado",
      flex: 1,

      renderCell: (params: any) => {
        if (params.value) {
          return (
            <span
              style={{
                background: "var(--aedas-green)",
                padding: "5px",
                borderRadius: "10px",
                color: "white",
              }}
            >
              Activado
            </span>
          );
        } else {
          return (
            <span
              style={{
                background: "var(--aedas-red)",
                padding: "5px",
                borderRadius: "10px",
                color: "white",
              }}
            >
              Desactivado
            </span>
          );
        }
      },
    },
  ];
  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }
  const CustomToolbar = () => {
    return (
      <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
        <IconButton onClick={() => setOpenModal(true)}>
          <AddCircleOutlineIcon></AddCircleOutlineIcon>
        </IconButton>
      </div>
    );
  };
  return (
    <>
      <CustomModal
        size="lg"
        show={openModal}
        onChange={() => {
          setOpenModal(!openModal);
        }}
        title="Nuevo usuario"
        body={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <form
              onSubmit={formik.handleSubmit}
              style={{ padding: "1rem", background: "white" }}
            >
              <p>Introduce el email del usuario que se desa añadir</p>
              <TextField
                required
                fullWidth
                id="email"
                label="Email"
                variant="outlined"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{ background: "var(--blue-lis)", color: "white" }}
                  type="submit"
                >
                  Añadir
                </Button>{" "}
              </div>
            </form>
          </div>
        }
      ></CustomModal>
      <div
        style={{
          height: "100vh",
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1 style={{ fontFamily: "lis-title", fontWeight: "bold" }}>
          Usuarios
        </h1>
        <div
          style={{
            height: "80%",
            width: "95%",
            padding: "1rem",
            background: "white",
            borderRadius: "10px",
          }}
        >
          <DataGrid
            style={{ height: "100%" }}
            rows={users}
            // onCellDoubleClick={(params, event) => {
            //   setRow(params.row);
            // }}

            columns={columns}
            pageSize={12}
            rowsPerPageOptions={[10]}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </div>
      </div>
    </>
  );
}

export default Users;
