import * as React from "react";
import axios from "axios";
import { toast } from "react-toastify";

export const loginAzure = async (token: string) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  return await axios.get(
    process.env.REACT_APP_API_BACKEND + "/loginAzure",
    config
  );
};
